import { useMediaQuery } from '@/hooks';
import { Link, useParams, useSearch } from '@tanstack/react-router';
import { ChevronDown, Info } from 'lucide-react';

import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';

import { useGetProjectRequestsStats } from '@/api/projects/queries';

import { ALL_STATUSES_OPTION, STATUSES } from '../-utils/config';

const LINKS = [ALL_STATUSES_OPTION, ...Object.values(STATUSES)];

export const Categories = () => {
  const { projectId } = useParams({ strict: false }) as { projectId: string };
  const { status } = useSearch({ strict: false }) as { status: string };
  const { data } = useGetProjectRequestsStats(projectId);
  const isDesktop = useMediaQuery('(min-width: 640px)');

  const activeCategory = LINKS.find((link) => link.status === status);

  if (!isDesktop) {
    return (
      <DropdownMenu>
        <DropdownMenuTrigger>
          <Button className="w-[200px] items-center justify-start gap-2 p-2" variant="outline">
            {activeCategory && (
              <>
                {activeCategory.Icon && (
                  <activeCategory.Icon className={activeCategory.iconClassName} size={18} />
                )}
                <p>{activeCategory.label}</p>
                <p className="text-sm text-slate-500">{data?.[activeCategory.status || 'total']}</p>
              </>
            )}
            <ChevronDown className="ml-auto text-muted-foreground" size={18} />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start">
          {LINKS.map(({ label, status: linkStatus, Icon, iconClassName }) => {
            return (
              <DropdownMenuItem asChild key={status}>
                <Link
                  key={linkStatus}
                  className="bold flex items-baseline px-2 py-2 transition-colors hover:text-slate-900 dark:hover:text-slate-100"
                  to="/projects/$projectId/requests"
                  search={{ status: linkStatus }}
                  params={{ projectId }}
                  activeProps={{
                    className:
                      'bg-slate-100 dark:bg-slate-900 rounded-lg text-slate-900 dark:text-slate-100',
                  }}
                  inactiveProps={{
                    className: 'text-slate-500',
                  }}
                >
                  {Icon && <Icon className={`${iconClassName} mr-2.5 self-center`} size={18} />}
                  <p>{label}</p>
                  <p className="ml-2 text-sm text-slate-500">{data?.[linkStatus || 'total']}</p>
                </Link>
              </DropdownMenuItem>
            );
          })}
        </DropdownMenuContent>
      </DropdownMenu>
    );
  }

  return (
    <div className="sticky top-16 flex w-full flex-col">
      <div className="text-baseline mb-4 ml-2 text-muted-foreground">
        Categories
        <Tooltip>
          <TooltipTrigger asChild>
            <Info className="ml-2 inline-block" size={16} />
          </TooltipTrigger>
          <TooltipContent className="text-muted-foreground">
            <p>In the app we show only 3 statuses:</p>
            <ul>
              <li>• Approved</li>
              <li>• In Progress</li>
              <li>• Implemented</li>
            </ul>
          </TooltipContent>
        </Tooltip>
      </div>
      <div>
        {LINKS.map(({ label, status: linkStatus, Icon, iconClassName }) => {
          return (
            <Link
              key={linkStatus}
              className="bold flex items-baseline px-2 py-2.5 text-sm transition-colors hover:text-slate-900 dark:hover:text-slate-100"
              to="/projects/$projectId/requests"
              search={{ status: linkStatus }}
              params={{ projectId }}
              activeProps={{
                className:
                  'bg-slate-100 dark:bg-slate-900 rounded-lg text-slate-900 dark:text-slate-100',
              }}
              inactiveProps={{
                className: 'text-slate-500',
              }}
            >
              {Icon && <Icon className={`${iconClassName} mr-2.5 self-center`} size={18} />}
              <p>{label}</p>
              <p className="ml-2 text-sm text-slate-500">{data?.[linkStatus || 'total']}</p>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

import AndroidIcon from '@/assets/platform-icons/android-icon.svg?react';
import IosIcon from '@/assets/platform-icons/apple-icon.svg?react';
import FlutterIcon from '@/assets/platform-icons/flutter-icon.svg?react';
import ReactNativeIcon from '@/assets/platform-icons/react-icon.svg?react';
import { toast } from '@/hooks/use-toast';
import { CopyIcon } from 'lucide-react';

import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';

import { Project } from '@/api/projects/types';

export type Props = {
  isOpen: boolean;
  project: Project | null;
  setIsOpen: (value: boolean) => void;
};

const LINKS = [
  {
    icon: IosIcon,
    platform: 'iOS',
    link: 'https://docs.featurebuddy.com/platforms/ios/getting-started/',
    disabled: true,
    className: 'text-black dark:text-white',
  },
  {
    icon: AndroidIcon,
    platform: 'Android',
    link: 'https://docs.featurebuddy.com/platforms/android/getting-started/',
    disabled: true,
  },
  {
    icon: ReactNativeIcon,
    platform: 'React Native',
    link: 'https://docs.featurebuddy.com/platforms/react-native/getting-started/',
    disabled: false,
  },
  {
    icon: FlutterIcon,
    platform: 'Flutter',
    link: 'https://docs.featurebuddy.com/platforms/flutter/getting-started/',
    disabled: true,
  },
];

const ProjectSetupDialog = ({ isOpen, project, setIsOpen }: Props) => {
  const handleCopyApiKeyClick = () => {
    if (!project?.apiKey) {
      return;
    }

    navigator.clipboard
      .writeText(project.apiKey)
      .then(() => toast({ title: 'The API key has been copied to the clipboard.' }))
      .catch(() => toast({ title: 'Failed to copy API key.', variant: 'destructive' }));
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="flex flex-col gap-8 p-6">
        <DialogHeader>
          <DialogTitle>Setup your project</DialogTitle>
          <DialogDescription>
            Copy this key and folow instructions below to setup your project.
          </DialogDescription>
          <DialogDescription>
            Later you can find this key in your project settings.
          </DialogDescription>
        </DialogHeader>
        <div className="flex items-center space-x-2">
          <div className="grid flex-1 gap-2">
            <Label htmlFor="link" className="sr-only">
              Link
            </Label>
            <Input id="link" defaultValue={project?.apiKey} readOnly />
          </div>
          <Button type="submit" size="sm" className="px-3" onClick={handleCopyApiKeyClick}>
            <span className="sr-only">Copy</span>
            <CopyIcon className="h-4 w-4" />
          </Button>
        </div>

        <div className="grid grid-cols-2 place-content-around place-items-center gap-4">
          {LINKS.map(({ icon: Icon, platform, link, disabled, className }) => (
            <Button
              variant="link"
              asChild
              className="flex h-48 w-48 border-2 hover:bg-slate-100/30 dark:hover:bg-slate-900/40"
              key={platform}
              disabled={disabled}
            >
              <a href={link} target="_blank">
                <Icon className={className} width={70} height={70} />
              </a>
            </Button>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ProjectSetupDialog;

import { cleanObject } from '@/utils';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { valibotSearchValidator } from '@tanstack/router-valibot-adapter';
import { Search, XCircle } from 'lucide-react';
import { useState } from 'react';
import { object, optional, picklist, string } from 'valibot';

import Loader from '@/components/loader';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { TooltipProvider } from '@/components/ui/tooltip';

import { useGetRequests } from '@/api/requests/queries';

import { Categories } from './-components/categories';
import { CreateRequestDialog } from './-components/create-request-dialog';
import { RequestSheet } from './-components/request-sheet';
import { RequestsList } from './-components/requests-list';
import { ALL_STATUSES_OPTION, STATUSES } from './-utils/config';

const VALID_STATUSES = [ALL_STATUSES_OPTION, ...Object.values(STATUSES)].map(
  ({ status }) => status,
);

const SORT_BY_OPTIONS = [
  { label: 'Newest', value: 'date:desc' },
  { label: 'Oldest', value: 'date:asc' },
  { label: 'Most voted', value: 'votes:desc' },
  { label: 'Least voted', value: 'votes:asc' },
];

const queryParamsSchema = object({
  status: optional(picklist(VALID_STATUSES), ALL_STATUSES_OPTION.status),
  sortBy: optional(picklist(SORT_BY_OPTIONS.map(({ value }) => value)), SORT_BY_OPTIONS[0].value),
  query: optional(string()),
  requestId: optional(string()),
});

export const Route = createFileRoute('/_authenticated/_projects/projects/$projectId/requests/')({
  component: RequestsComponent,
  validateSearch: valibotSearchValidator(queryParamsSchema),
});

function RequestsComponent() {
  const navigate = useNavigate({ from: Route.fullPath });
  const { projectId } = Route.useParams();
  const search = Route.useSearch();
  const { data, isPending } = useGetRequests(
    projectId,
    cleanObject({ status: search.status, sortBy: search.sortBy }),
  );

  const [isRequestDialogOpened, setIsRequestDialogOpened] = useState(!!search.requestId);
  const [selectedRequestId, setSelectedRequestId] = useState(search.requestId || '');

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    navigate({ search: (prev) => ({ ...prev, query: event.target.value }), params: true });
  };

  const handleSearchReset = () => {
    navigate({ search: (prev) => ({ ...prev, query: '' }), params: true });
  };

  const handleSortByChange = (value: string) => {
    navigate({ search: (prev) => ({ ...prev, sortBy: value }), params: true });
  };

  const handleRequestSheetOpen = (requestId: string) => {
    setIsRequestDialogOpened(true);
    setSelectedRequestId(requestId);
    navigate({ search: (prev) => ({ ...prev, requestId }), params: true });
  };

  const handleRequestSheetClose = () => {
    setIsRequestDialogOpened(false);
    setSelectedRequestId('');
    navigate({ search: (prev) => ({ ...prev, requestId: '' }), params: true });
  };

  // TODO: add debounce for actual search
  const dataToDisplay = (data || []).filter((request) =>
    request.name.toLowerCase().includes(search.query ?? ''),
  );

  return (
    <TooltipProvider delayDuration={100}>
      <div className="flex flex-1 flex-col items-center px-4 pb-12 pt-3 md:px-8">
        <div className="flex w-full flex-col">
          <div className="mt-6 flex items-center justify-between">
            <h1 className="text-3xl">Feature Requests</h1>
            <CreateRequestDialog />
          </div>
          <div className="mt-6 flex flex-1 flex-col gap-6 sm:flex-row">
            <div className="w-full sm:w-[210px]">
              <Categories />
            </div>
            <div className="flex flex-1 flex-col space-y-4">
              <div className="flex space-x-3">
                <div className="relative flex flex-1 items-center">
                  <Search
                    className="pointer-events-none absolute left-3 text-slate-500"
                    size={18}
                  />
                  <Input
                    className="px-10"
                    placeholder="Search"
                    value={search.query}
                    onChange={handleSearchChange}
                  />
                  {Boolean(search.query) && (
                    <XCircle
                      className="absolute right-1 p-1.5 text-slate-500 transition-colors hover:cursor-pointer hover:text-slate-600 dark:hover:text-slate-400"
                      onClick={handleSearchReset}
                      size={36}
                    />
                  )}
                </div>
                <Select defaultValue={search.sortBy} onValueChange={handleSortByChange}>
                  <SelectTrigger className="w-[180px]">
                    <span className="text-slate-500">Sort:</span>
                    <SelectValue placeholder="Sort by" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {SORT_BY_OPTIONS.map(({ label, value }) => (
                        <SelectItem key={value} value={value}>
                          {label}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
              {isPending ? (
                <div>
                  <Loader className="mx-auto" />
                </div>
              ) : dataToDisplay?.length ? (
                <RequestsList data={dataToDisplay} onItemClick={handleRequestSheetOpen} />
              ) : (
                <div className="flex h-[280px] items-center justify-center">
                  <span className="text-muted-foreground">
                    {search.query
                      ? `Nothing was found for the query "${search.query}"`
                      : 'No requests'}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {isRequestDialogOpened && (
        <RequestSheet
          isOpen={isRequestDialogOpened}
          setIsOpen={handleRequestSheetClose}
          requestId={selectedRequestId}
          onRequestDelete={handleRequestSheetClose}
        />
      )}
    </TooltipProvider>
  );
}

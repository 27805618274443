import { Check, Lightbulb, Loader, LucideIcon, PackageCheck, XCircle } from 'lucide-react';

export type StatusType = {
  status: string;
  label: string;
  Icon: LucideIcon;
  iconClassName: string;
};

export const STATUSES: Record<string, StatusType> = {
  pending: {
    status: 'pending',
    label: 'Pending',
    Icon: Lightbulb,
    iconClassName: 'stroke-indigo-950 dark:stroke-indigo-100',
  },
  approved: {
    status: 'approved',
    label: 'Approved',
    Icon: Check,
    iconClassName: 'stroke-green-700 dark:stroke-green-500',
  },
  'in-progress': {
    status: 'in-progress',
    label: 'In Progress',
    Icon: Loader,
    iconClassName: 'stroke-purple-700 dark:stroke-purple-400',
  },
  done: {
    status: 'done',
    label: 'Implemented',
    Icon: PackageCheck,
    iconClassName: 'stroke-blue-700 dark:stroke-blue-500',
  },
  rejected: {
    status: 'rejected',
    label: 'Rejected',
    Icon: XCircle,
    iconClassName: 'stroke-rose-500',
  },
};

export const ALL_STATUSES_OPTION = {
  status: 'all',
  label: 'All Requests',
  Icon: null,
  iconClassName: 'stroke-black dark:stroke-white',
};

import { toast } from '@/hooks/use-toast';
import { useIsFreePlan } from '@/hooks/use-user-plan';
import { Link, useParams } from '@tanstack/react-router';
import { formatDistanceToNow } from 'date-fns';
import { EyeOff, Trash2, User } from 'lucide-react';
import { useState } from 'react';

import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { UserAvatar } from '@/components/user-avatar';

import { useDeleteComment } from '@/api/comments/queries';
import { Comment } from '@/api/comments/types';

import { Route } from '..';
import { ConfirmDeleteAlert } from './confirm-delete-alert';

type Props = {
  comment: Comment;
  isAuthor: boolean;
};

export const RequestComment = ({ comment, isAuthor }: Props) => {
  const isFreePlan = useIsFreePlan();
  const deleteMutation = useDeleteComment();
  const { projectId } = useParams({ strict: false }) as { projectId: string };
  const search = Route.useSearch();
  const [commentToRemove, setCommentToRemove] = useState<Comment | null>(null);

  const handleDeleteComment = (confirmed: boolean) => {
    if (!confirmed) {
      return setCommentToRemove(null);
    }

    if (!commentToRemove) {
      return;
    }

    setCommentToRemove(null);
    deleteMutation.mutateAsync(
      {
        projectId: projectId,
        requestId: commentToRemove.requestId,
        commentId: commentToRemove.id,
        status: search,
      },
      { onSuccess: () => toast({ title: 'Comment had been removed' }) },
    );
  };

  return (
    <div className="flex gap-x-2.5" key={comment.id}>
      <div>
        <UserAvatar userId={comment.userId} />
      </div>
      <div className="space-y-0.5 text-sm">
        <span className="text-xs text-muted-foreground">
          <User className="mr-0.5 inline" size={12} />
          <Link
            to="/projects/$projectId/users/$userId"
            params={{ projectId, userId: comment.userId }}
          >
            {comment.userId} {isAuthor ? <Badge variant="secondary">Author</Badge> : null}
          </Link>
        </span>
        <div>{comment.text}</div>
        <div className="inline-flex items-center space-x-2 text-xs text-muted-foreground/60">
          <span>{formatDistanceToNow(comment.createdAt)} ago</span>
          {!isFreePlan && (
            <>
              {!comment.visible ? <EyeOff size={14} /> : null}
              <span>•</span>
              <Button
                className="h-5 shrink-0 gap-1 p-0 text-xs text-red-500 hover:bg-transparent"
                variant="ghost"
                onClick={() => setCommentToRemove(comment)}
              >
                <Trash2 size={12} />
                Remove
              </Button>
            </>
          )}
        </div>
      </div>
      <ConfirmDeleteAlert isOpen={!!commentToRemove} onAction={handleDeleteComment} />
    </div>
  );
};

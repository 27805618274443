import { DropdownMenuContentProps, DropdownMenuLabel } from '@radix-ui/react-dropdown-menu';
import { Trash } from 'lucide-react';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

import { STATUSES } from '../-utils/config';

type Props = {
  children: React.ReactNode;
  currentStatus: string;
  isWithRemoveOption: boolean;
  onSelect: (status: string) => void;
  onRemove: () => void;
  popperContentProps?: DropdownMenuContentProps;
};

export const ChangeStatusDropdown = ({
  children,
  currentStatus,
  isWithRemoveOption,
  onSelect,
  onRemove,
  popperContentProps,
}: Props) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>{children}</DropdownMenuTrigger>
      <DropdownMenuContent
        onClick={(event) => {
          event.stopPropagation();
        }}
        className="w-40"
        side="bottom"
        align="end"
        {...popperContentProps}
      >
        <DropdownMenuLabel className="ml-8 text-xs leading-[25px] text-muted-foreground">
          Change status
        </DropdownMenuLabel>
        <DropdownMenuSeparator className="mx-1" />
        <DropdownMenuRadioGroup onValueChange={(value) => onSelect(value)} value={currentStatus}>
          {Object.values(STATUSES).map((status) => {
            return (
              <DropdownMenuRadioItem
                value={status.status}
                key={status.status}
                disabled={currentStatus === status.status}
              >
                <p className="mr-auto">{status.label}</p>
                <status.Icon className={status.iconClassName} size={18} />
              </DropdownMenuRadioItem>
            );
          })}
        </DropdownMenuRadioGroup>
        {isWithRemoveOption && (
          <>
            <DropdownMenuSeparator className="mx-1" />
            <DropdownMenuGroup>
              <DropdownMenuItem
                className="items-center text-red-500"
                onClick={(event) => {
                  event.stopPropagation();
                  onRemove();
                }}
              >
                <Trash size={16} className="mr-2" /> Remove
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

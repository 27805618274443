import { toast } from '@/hooks/use-toast';
import { useUser } from '@clerk/clerk-react';
import { Link, createFileRoute } from '@tanstack/react-router';
import { Star } from 'lucide-react';
import { useState } from 'react';

import Loader from '@/components/loader';

import { useCreateProject, useGetProjects } from '@/api/projects/queries';
import { CreateProjectDto, Project } from '@/api/projects/types';

import CreateProjectDialog from './-components/create-project-dialog';
import { ProjectItem } from './-components/project-item';
import ProjectSetupDialog from './-components/project-setup-dialog';

export const Route = createFileRoute('/_authenticated/_global/projects/')({
  component: ProjectsComponent,
});

function ProjectsComponent() {
  const { data, isLoading } = useGetProjects();
  const mutation = useCreateProject();
  const [createdProject, setCreatedProject] = useState<null | Project>(null);
  const [isSetupDialogOpen, setIsSetupDialogOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { user } = useUser();

  const allowedProjectsCount = user?.publicMetadata?.allowedProjectsCount;
  const allowProjectCreation =
    typeof allowedProjectsCount === 'number' && allowedProjectsCount > (data?.length || 0);

  const onSubmit = async (data: CreateProjectDto) => {
    return mutation.mutateAsync(data, {
      onSuccess: (data) => {
        setCreatedProject(data);
        setIsSetupDialogOpen(true);
        setIsDialogOpen(false);
        toast({ title: 'Project has been created' });
      },
    });
  };

  if (isLoading) {
    return <Loader className="mx-auto h-full" />;
  }

  return (
    <div className="mx-auto flex h-screen w-full max-w-6xl flex-col px-4 py-3 md:px-8">
      <div className="mt-10 flex flex-none flex-wrap items-center justify-between gap-2 pt-12">
        <div className="text-3xl">Projects</div>
        {allowProjectCreation ? (
          <CreateProjectDialog
            onSubmit={onSubmit}
            isOpen={isDialogOpen}
            setIsOpen={setIsDialogOpen}
          />
        ) : (
          <span className="text-sm text-muted-foreground">
            You've reached projects limit.
            <Link
              to="/pricing"
              className="group ml-3 inline-flex items-center rounded-md bg-gradient-to-r from-purple-500 to-indigo-500 px-4 py-2 text-white transition-colors hover:from-purple-600 hover:to-indigo-600 dark:hover:from-purple-400 dark:hover:to-indigo-400"
            >
              <Star className="mr-2 h-4 w-4 fill-current text-white/75 transition-transform group-hover:scale-125" />
              <span className="transition-transform group-hover:translate-x-0.5">Upgrade</span>
            </Link>
          </span>
        )}
      </div>
      <div className="mt-6 grid gap-6 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3">
        {data?.length ? (
          data.map((project) => {
            return <ProjectItem key={project.id} project={project} />;
          })
        ) : (
          <div className="col-span-3 opacity-70">
            <p className="flex justify-center">No projects created yet</p>
          </div>
        )}
      </div>
      <ProjectSetupDialog
        project={createdProject}
        isOpen={isSetupDialogOpen}
        setIsOpen={setIsSetupDialogOpen}
      />
    </div>
  );
}

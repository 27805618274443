import { Link, createFileRoute } from '@tanstack/react-router';
import { CheckIcon } from 'lucide-react';

export const Route = createFileRoute('/_authenticated/_global/pricing/success')({
  component: SuccessComponent,
});

function SuccessComponent() {
  return (
    <div className="flex min-h-[100dvh] flex-col items-center justify-center bg-background">
      <div className="mx-auto max-w-md text-center">
        <div className="inline-flex h-12 w-12 items-center justify-center rounded-full bg-green-500">
          <CheckIcon className="h-6 w-6 text-white" />
        </div>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-foreground sm:text-4xl">
          Congratulations!
        </h1>
        <p className="mt-4 text-muted-foreground">
          Your payment was successful. Thank you for your support.
        </p>
        <div className="mt-6">
          <Link
            to="/projects"
            className="inline-flex items-center rounded-md bg-primary px-4 py-2 text-sm font-medium text-primary-foreground shadow-sm transition-colors hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
          >
            Go and build something amazing!
          </Link>
        </div>
      </div>
    </div>
  );
}

/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as SignUpImport } from './routes/sign-up'
import { Route as SignInImport } from './routes/sign-in'
import { Route as BoardImport } from './routes/_board'
import { Route as AuthenticatedImport } from './routes/_authenticated'
import { Route as IndexImport } from './routes/index'
import { Route as AuthenticatedProjectsImport } from './routes/_authenticated._projects'
import { Route as AuthenticatedGlobalImport } from './routes/_authenticated._global'
import { Route as BoardBoardBoardIdImport } from './routes/_board/board/$boardId'
import { Route as AuthenticatedGlobalUserSettingsImport } from './routes/_authenticated/_global/user-settings'
import { Route as AuthenticatedGlobalBillingImport } from './routes/_authenticated/_global/billing'
import { Route as AuthenticatedGlobalProjectsIndexImport } from './routes/_authenticated/_global/projects/index'
import { Route as AuthenticatedGlobalPricingIndexImport } from './routes/_authenticated/_global/pricing/index'
import { Route as AuthenticatedGlobalPricingSuccessImport } from './routes/_authenticated/_global/pricing/success'
import { Route as AuthenticatedGlobalPricingCanceledImport } from './routes/_authenticated/_global/pricing/canceled'
import { Route as AuthenticatedProjectsProjectsProjectIdRequestsIndexImport } from './routes/_authenticated/_projects/projects/$projectId/requests/index'

// Create Virtual Routes

const BoardBoardIndexLazyImport = createFileRoute('/_board/board/')()
const AuthenticatedProjectsProjectsProjectIdUsersIndexLazyImport =
  createFileRoute('/_authenticated/_projects/projects/$projectId/users/')()
const AuthenticatedProjectsProjectsProjectIdStatsIndexLazyImport =
  createFileRoute('/_authenticated/_projects/projects/$projectId/stats/')()
const AuthenticatedProjectsProjectsProjectIdSettingsIndexLazyImport =
  createFileRoute('/_authenticated/_projects/projects/$projectId/settings/')()
const AuthenticatedProjectsProjectsProjectIdUsersUserIdIndexLazyImport =
  createFileRoute(
    '/_authenticated/_projects/projects/$projectId/users/$userId/',
  )()

// Create/Update Routes

const SignUpRoute = SignUpImport.update({
  id: '/sign-up',
  path: '/sign-up',
  getParentRoute: () => rootRoute,
} as any)

const SignInRoute = SignInImport.update({
  id: '/sign-in',
  path: '/sign-in',
  getParentRoute: () => rootRoute,
} as any)

const BoardRoute = BoardImport.update({
  id: '/_board',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedRoute = AuthenticatedImport.update({
  id: '/_authenticated',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedProjectsRoute = AuthenticatedProjectsImport.update({
  id: '/_projects',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedGlobalRoute = AuthenticatedGlobalImport.update({
  id: '/_global',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const BoardBoardIndexLazyRoute = BoardBoardIndexLazyImport.update({
  id: '/board/',
  path: '/board/',
  getParentRoute: () => BoardRoute,
} as any).lazy(() =>
  import('./routes/_board/board/index.lazy').then((d) => d.Route),
)

const BoardBoardBoardIdRoute = BoardBoardBoardIdImport.update({
  id: '/board/$boardId',
  path: '/board/$boardId',
  getParentRoute: () => BoardRoute,
} as any)

const AuthenticatedGlobalUserSettingsRoute =
  AuthenticatedGlobalUserSettingsImport.update({
    id: '/user-settings',
    path: '/user-settings',
    getParentRoute: () => AuthenticatedGlobalRoute,
  } as any)

const AuthenticatedGlobalBillingRoute = AuthenticatedGlobalBillingImport.update(
  {
    id: '/billing',
    path: '/billing',
    getParentRoute: () => AuthenticatedGlobalRoute,
  } as any,
)

const AuthenticatedGlobalProjectsIndexRoute =
  AuthenticatedGlobalProjectsIndexImport.update({
    id: '/projects/',
    path: '/projects/',
    getParentRoute: () => AuthenticatedGlobalRoute,
  } as any)

const AuthenticatedGlobalPricingIndexRoute =
  AuthenticatedGlobalPricingIndexImport.update({
    id: '/pricing/',
    path: '/pricing/',
    getParentRoute: () => AuthenticatedGlobalRoute,
  } as any)

const AuthenticatedGlobalPricingSuccessRoute =
  AuthenticatedGlobalPricingSuccessImport.update({
    id: '/pricing/success',
    path: '/pricing/success',
    getParentRoute: () => AuthenticatedGlobalRoute,
  } as any)

const AuthenticatedGlobalPricingCanceledRoute =
  AuthenticatedGlobalPricingCanceledImport.update({
    id: '/pricing/canceled',
    path: '/pricing/canceled',
    getParentRoute: () => AuthenticatedGlobalRoute,
  } as any)

const AuthenticatedProjectsProjectsProjectIdUsersIndexLazyRoute =
  AuthenticatedProjectsProjectsProjectIdUsersIndexLazyImport.update({
    id: '/projects/$projectId/users/',
    path: '/projects/$projectId/users/',
    getParentRoute: () => AuthenticatedProjectsRoute,
  } as any).lazy(() =>
    import(
      './routes/_authenticated/_projects/projects/$projectId/users/index.lazy'
    ).then((d) => d.Route),
  )

const AuthenticatedProjectsProjectsProjectIdStatsIndexLazyRoute =
  AuthenticatedProjectsProjectsProjectIdStatsIndexLazyImport.update({
    id: '/projects/$projectId/stats/',
    path: '/projects/$projectId/stats/',
    getParentRoute: () => AuthenticatedProjectsRoute,
  } as any).lazy(() =>
    import(
      './routes/_authenticated/_projects/projects/$projectId/stats/index.lazy'
    ).then((d) => d.Route),
  )

const AuthenticatedProjectsProjectsProjectIdSettingsIndexLazyRoute =
  AuthenticatedProjectsProjectsProjectIdSettingsIndexLazyImport.update({
    id: '/projects/$projectId/settings/',
    path: '/projects/$projectId/settings/',
    getParentRoute: () => AuthenticatedProjectsRoute,
  } as any).lazy(() =>
    import(
      './routes/_authenticated/_projects/projects/$projectId/settings/index.lazy'
    ).then((d) => d.Route),
  )

const AuthenticatedProjectsProjectsProjectIdRequestsIndexRoute =
  AuthenticatedProjectsProjectsProjectIdRequestsIndexImport.update({
    id: '/projects/$projectId/requests/',
    path: '/projects/$projectId/requests/',
    getParentRoute: () => AuthenticatedProjectsRoute,
  } as any)

const AuthenticatedProjectsProjectsProjectIdUsersUserIdIndexLazyRoute =
  AuthenticatedProjectsProjectsProjectIdUsersUserIdIndexLazyImport.update({
    id: '/projects/$projectId/users/$userId/',
    path: '/projects/$projectId/users/$userId/',
    getParentRoute: () => AuthenticatedProjectsRoute,
  } as any).lazy(() =>
    import(
      './routes/_authenticated/_projects/projects/$projectId/users/$userId/index.lazy'
    ).then((d) => d.Route),
  )

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated': {
      id: '/_authenticated'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedImport
      parentRoute: typeof rootRoute
    }
    '/_board': {
      id: '/_board'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof BoardImport
      parentRoute: typeof rootRoute
    }
    '/sign-in': {
      id: '/sign-in'
      path: '/sign-in'
      fullPath: '/sign-in'
      preLoaderRoute: typeof SignInImport
      parentRoute: typeof rootRoute
    }
    '/sign-up': {
      id: '/sign-up'
      path: '/sign-up'
      fullPath: '/sign-up'
      preLoaderRoute: typeof SignUpImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated/_global': {
      id: '/_authenticated/_global'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedGlobalImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/_projects': {
      id: '/_authenticated/_projects'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedProjectsImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/_global/billing': {
      id: '/_authenticated/_global/billing'
      path: '/billing'
      fullPath: '/billing'
      preLoaderRoute: typeof AuthenticatedGlobalBillingImport
      parentRoute: typeof AuthenticatedGlobalImport
    }
    '/_authenticated/_global/user-settings': {
      id: '/_authenticated/_global/user-settings'
      path: '/user-settings'
      fullPath: '/user-settings'
      preLoaderRoute: typeof AuthenticatedGlobalUserSettingsImport
      parentRoute: typeof AuthenticatedGlobalImport
    }
    '/_board/board/$boardId': {
      id: '/_board/board/$boardId'
      path: '/board/$boardId'
      fullPath: '/board/$boardId'
      preLoaderRoute: typeof BoardBoardBoardIdImport
      parentRoute: typeof BoardImport
    }
    '/_board/board/': {
      id: '/_board/board/'
      path: '/board'
      fullPath: '/board'
      preLoaderRoute: typeof BoardBoardIndexLazyImport
      parentRoute: typeof BoardImport
    }
    '/_authenticated/_global/pricing/canceled': {
      id: '/_authenticated/_global/pricing/canceled'
      path: '/pricing/canceled'
      fullPath: '/pricing/canceled'
      preLoaderRoute: typeof AuthenticatedGlobalPricingCanceledImport
      parentRoute: typeof AuthenticatedGlobalImport
    }
    '/_authenticated/_global/pricing/success': {
      id: '/_authenticated/_global/pricing/success'
      path: '/pricing/success'
      fullPath: '/pricing/success'
      preLoaderRoute: typeof AuthenticatedGlobalPricingSuccessImport
      parentRoute: typeof AuthenticatedGlobalImport
    }
    '/_authenticated/_global/pricing/': {
      id: '/_authenticated/_global/pricing/'
      path: '/pricing'
      fullPath: '/pricing'
      preLoaderRoute: typeof AuthenticatedGlobalPricingIndexImport
      parentRoute: typeof AuthenticatedGlobalImport
    }
    '/_authenticated/_global/projects/': {
      id: '/_authenticated/_global/projects/'
      path: '/projects'
      fullPath: '/projects'
      preLoaderRoute: typeof AuthenticatedGlobalProjectsIndexImport
      parentRoute: typeof AuthenticatedGlobalImport
    }
    '/_authenticated/_projects/projects/$projectId/requests/': {
      id: '/_authenticated/_projects/projects/$projectId/requests/'
      path: '/projects/$projectId/requests'
      fullPath: '/projects/$projectId/requests'
      preLoaderRoute: typeof AuthenticatedProjectsProjectsProjectIdRequestsIndexImport
      parentRoute: typeof AuthenticatedProjectsImport
    }
    '/_authenticated/_projects/projects/$projectId/settings/': {
      id: '/_authenticated/_projects/projects/$projectId/settings/'
      path: '/projects/$projectId/settings'
      fullPath: '/projects/$projectId/settings'
      preLoaderRoute: typeof AuthenticatedProjectsProjectsProjectIdSettingsIndexLazyImport
      parentRoute: typeof AuthenticatedProjectsImport
    }
    '/_authenticated/_projects/projects/$projectId/stats/': {
      id: '/_authenticated/_projects/projects/$projectId/stats/'
      path: '/projects/$projectId/stats'
      fullPath: '/projects/$projectId/stats'
      preLoaderRoute: typeof AuthenticatedProjectsProjectsProjectIdStatsIndexLazyImport
      parentRoute: typeof AuthenticatedProjectsImport
    }
    '/_authenticated/_projects/projects/$projectId/users/': {
      id: '/_authenticated/_projects/projects/$projectId/users/'
      path: '/projects/$projectId/users'
      fullPath: '/projects/$projectId/users'
      preLoaderRoute: typeof AuthenticatedProjectsProjectsProjectIdUsersIndexLazyImport
      parentRoute: typeof AuthenticatedProjectsImport
    }
    '/_authenticated/_projects/projects/$projectId/users/$userId/': {
      id: '/_authenticated/_projects/projects/$projectId/users/$userId/'
      path: '/projects/$projectId/users/$userId'
      fullPath: '/projects/$projectId/users/$userId'
      preLoaderRoute: typeof AuthenticatedProjectsProjectsProjectIdUsersUserIdIndexLazyImport
      parentRoute: typeof AuthenticatedProjectsImport
    }
  }
}

// Create and export the route tree

interface AuthenticatedGlobalRouteChildren {
  AuthenticatedGlobalBillingRoute: typeof AuthenticatedGlobalBillingRoute
  AuthenticatedGlobalUserSettingsRoute: typeof AuthenticatedGlobalUserSettingsRoute
  AuthenticatedGlobalPricingCanceledRoute: typeof AuthenticatedGlobalPricingCanceledRoute
  AuthenticatedGlobalPricingSuccessRoute: typeof AuthenticatedGlobalPricingSuccessRoute
  AuthenticatedGlobalPricingIndexRoute: typeof AuthenticatedGlobalPricingIndexRoute
  AuthenticatedGlobalProjectsIndexRoute: typeof AuthenticatedGlobalProjectsIndexRoute
}

const AuthenticatedGlobalRouteChildren: AuthenticatedGlobalRouteChildren = {
  AuthenticatedGlobalBillingRoute: AuthenticatedGlobalBillingRoute,
  AuthenticatedGlobalUserSettingsRoute: AuthenticatedGlobalUserSettingsRoute,
  AuthenticatedGlobalPricingCanceledRoute:
    AuthenticatedGlobalPricingCanceledRoute,
  AuthenticatedGlobalPricingSuccessRoute:
    AuthenticatedGlobalPricingSuccessRoute,
  AuthenticatedGlobalPricingIndexRoute: AuthenticatedGlobalPricingIndexRoute,
  AuthenticatedGlobalProjectsIndexRoute: AuthenticatedGlobalProjectsIndexRoute,
}

const AuthenticatedGlobalRouteWithChildren =
  AuthenticatedGlobalRoute._addFileChildren(AuthenticatedGlobalRouteChildren)

interface AuthenticatedProjectsRouteChildren {
  AuthenticatedProjectsProjectsProjectIdRequestsIndexRoute: typeof AuthenticatedProjectsProjectsProjectIdRequestsIndexRoute
  AuthenticatedProjectsProjectsProjectIdSettingsIndexLazyRoute: typeof AuthenticatedProjectsProjectsProjectIdSettingsIndexLazyRoute
  AuthenticatedProjectsProjectsProjectIdStatsIndexLazyRoute: typeof AuthenticatedProjectsProjectsProjectIdStatsIndexLazyRoute
  AuthenticatedProjectsProjectsProjectIdUsersIndexLazyRoute: typeof AuthenticatedProjectsProjectsProjectIdUsersIndexLazyRoute
  AuthenticatedProjectsProjectsProjectIdUsersUserIdIndexLazyRoute: typeof AuthenticatedProjectsProjectsProjectIdUsersUserIdIndexLazyRoute
}

const AuthenticatedProjectsRouteChildren: AuthenticatedProjectsRouteChildren = {
  AuthenticatedProjectsProjectsProjectIdRequestsIndexRoute:
    AuthenticatedProjectsProjectsProjectIdRequestsIndexRoute,
  AuthenticatedProjectsProjectsProjectIdSettingsIndexLazyRoute:
    AuthenticatedProjectsProjectsProjectIdSettingsIndexLazyRoute,
  AuthenticatedProjectsProjectsProjectIdStatsIndexLazyRoute:
    AuthenticatedProjectsProjectsProjectIdStatsIndexLazyRoute,
  AuthenticatedProjectsProjectsProjectIdUsersIndexLazyRoute:
    AuthenticatedProjectsProjectsProjectIdUsersIndexLazyRoute,
  AuthenticatedProjectsProjectsProjectIdUsersUserIdIndexLazyRoute:
    AuthenticatedProjectsProjectsProjectIdUsersUserIdIndexLazyRoute,
}

const AuthenticatedProjectsRouteWithChildren =
  AuthenticatedProjectsRoute._addFileChildren(
    AuthenticatedProjectsRouteChildren,
  )

interface AuthenticatedRouteChildren {
  AuthenticatedGlobalRoute: typeof AuthenticatedGlobalRouteWithChildren
  AuthenticatedProjectsRoute: typeof AuthenticatedProjectsRouteWithChildren
}

const AuthenticatedRouteChildren: AuthenticatedRouteChildren = {
  AuthenticatedGlobalRoute: AuthenticatedGlobalRouteWithChildren,
  AuthenticatedProjectsRoute: AuthenticatedProjectsRouteWithChildren,
}

const AuthenticatedRouteWithChildren = AuthenticatedRoute._addFileChildren(
  AuthenticatedRouteChildren,
)

interface BoardRouteChildren {
  BoardBoardBoardIdRoute: typeof BoardBoardBoardIdRoute
  BoardBoardIndexLazyRoute: typeof BoardBoardIndexLazyRoute
}

const BoardRouteChildren: BoardRouteChildren = {
  BoardBoardBoardIdRoute: BoardBoardBoardIdRoute,
  BoardBoardIndexLazyRoute: BoardBoardIndexLazyRoute,
}

const BoardRouteWithChildren = BoardRoute._addFileChildren(BoardRouteChildren)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '': typeof AuthenticatedProjectsRouteWithChildren
  '/sign-in': typeof SignInRoute
  '/sign-up': typeof SignUpRoute
  '/billing': typeof AuthenticatedGlobalBillingRoute
  '/user-settings': typeof AuthenticatedGlobalUserSettingsRoute
  '/board/$boardId': typeof BoardBoardBoardIdRoute
  '/board': typeof BoardBoardIndexLazyRoute
  '/pricing/canceled': typeof AuthenticatedGlobalPricingCanceledRoute
  '/pricing/success': typeof AuthenticatedGlobalPricingSuccessRoute
  '/pricing': typeof AuthenticatedGlobalPricingIndexRoute
  '/projects': typeof AuthenticatedGlobalProjectsIndexRoute
  '/projects/$projectId/requests': typeof AuthenticatedProjectsProjectsProjectIdRequestsIndexRoute
  '/projects/$projectId/settings': typeof AuthenticatedProjectsProjectsProjectIdSettingsIndexLazyRoute
  '/projects/$projectId/stats': typeof AuthenticatedProjectsProjectsProjectIdStatsIndexLazyRoute
  '/projects/$projectId/users': typeof AuthenticatedProjectsProjectsProjectIdUsersIndexLazyRoute
  '/projects/$projectId/users/$userId': typeof AuthenticatedProjectsProjectsProjectIdUsersUserIdIndexLazyRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '': typeof AuthenticatedProjectsRouteWithChildren
  '/sign-in': typeof SignInRoute
  '/sign-up': typeof SignUpRoute
  '/billing': typeof AuthenticatedGlobalBillingRoute
  '/user-settings': typeof AuthenticatedGlobalUserSettingsRoute
  '/board/$boardId': typeof BoardBoardBoardIdRoute
  '/board': typeof BoardBoardIndexLazyRoute
  '/pricing/canceled': typeof AuthenticatedGlobalPricingCanceledRoute
  '/pricing/success': typeof AuthenticatedGlobalPricingSuccessRoute
  '/pricing': typeof AuthenticatedGlobalPricingIndexRoute
  '/projects': typeof AuthenticatedGlobalProjectsIndexRoute
  '/projects/$projectId/requests': typeof AuthenticatedProjectsProjectsProjectIdRequestsIndexRoute
  '/projects/$projectId/settings': typeof AuthenticatedProjectsProjectsProjectIdSettingsIndexLazyRoute
  '/projects/$projectId/stats': typeof AuthenticatedProjectsProjectsProjectIdStatsIndexLazyRoute
  '/projects/$projectId/users': typeof AuthenticatedProjectsProjectsProjectIdUsersIndexLazyRoute
  '/projects/$projectId/users/$userId': typeof AuthenticatedProjectsProjectsProjectIdUsersUserIdIndexLazyRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/_authenticated': typeof AuthenticatedRouteWithChildren
  '/_board': typeof BoardRouteWithChildren
  '/sign-in': typeof SignInRoute
  '/sign-up': typeof SignUpRoute
  '/_authenticated/_global': typeof AuthenticatedGlobalRouteWithChildren
  '/_authenticated/_projects': typeof AuthenticatedProjectsRouteWithChildren
  '/_authenticated/_global/billing': typeof AuthenticatedGlobalBillingRoute
  '/_authenticated/_global/user-settings': typeof AuthenticatedGlobalUserSettingsRoute
  '/_board/board/$boardId': typeof BoardBoardBoardIdRoute
  '/_board/board/': typeof BoardBoardIndexLazyRoute
  '/_authenticated/_global/pricing/canceled': typeof AuthenticatedGlobalPricingCanceledRoute
  '/_authenticated/_global/pricing/success': typeof AuthenticatedGlobalPricingSuccessRoute
  '/_authenticated/_global/pricing/': typeof AuthenticatedGlobalPricingIndexRoute
  '/_authenticated/_global/projects/': typeof AuthenticatedGlobalProjectsIndexRoute
  '/_authenticated/_projects/projects/$projectId/requests/': typeof AuthenticatedProjectsProjectsProjectIdRequestsIndexRoute
  '/_authenticated/_projects/projects/$projectId/settings/': typeof AuthenticatedProjectsProjectsProjectIdSettingsIndexLazyRoute
  '/_authenticated/_projects/projects/$projectId/stats/': typeof AuthenticatedProjectsProjectsProjectIdStatsIndexLazyRoute
  '/_authenticated/_projects/projects/$projectId/users/': typeof AuthenticatedProjectsProjectsProjectIdUsersIndexLazyRoute
  '/_authenticated/_projects/projects/$projectId/users/$userId/': typeof AuthenticatedProjectsProjectsProjectIdUsersUserIdIndexLazyRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | ''
    | '/sign-in'
    | '/sign-up'
    | '/billing'
    | '/user-settings'
    | '/board/$boardId'
    | '/board'
    | '/pricing/canceled'
    | '/pricing/success'
    | '/pricing'
    | '/projects'
    | '/projects/$projectId/requests'
    | '/projects/$projectId/settings'
    | '/projects/$projectId/stats'
    | '/projects/$projectId/users'
    | '/projects/$projectId/users/$userId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | ''
    | '/sign-in'
    | '/sign-up'
    | '/billing'
    | '/user-settings'
    | '/board/$boardId'
    | '/board'
    | '/pricing/canceled'
    | '/pricing/success'
    | '/pricing'
    | '/projects'
    | '/projects/$projectId/requests'
    | '/projects/$projectId/settings'
    | '/projects/$projectId/stats'
    | '/projects/$projectId/users'
    | '/projects/$projectId/users/$userId'
  id:
    | '__root__'
    | '/'
    | '/_authenticated'
    | '/_board'
    | '/sign-in'
    | '/sign-up'
    | '/_authenticated/_global'
    | '/_authenticated/_projects'
    | '/_authenticated/_global/billing'
    | '/_authenticated/_global/user-settings'
    | '/_board/board/$boardId'
    | '/_board/board/'
    | '/_authenticated/_global/pricing/canceled'
    | '/_authenticated/_global/pricing/success'
    | '/_authenticated/_global/pricing/'
    | '/_authenticated/_global/projects/'
    | '/_authenticated/_projects/projects/$projectId/requests/'
    | '/_authenticated/_projects/projects/$projectId/settings/'
    | '/_authenticated/_projects/projects/$projectId/stats/'
    | '/_authenticated/_projects/projects/$projectId/users/'
    | '/_authenticated/_projects/projects/$projectId/users/$userId/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AuthenticatedRoute: typeof AuthenticatedRouteWithChildren
  BoardRoute: typeof BoardRouteWithChildren
  SignInRoute: typeof SignInRoute
  SignUpRoute: typeof SignUpRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AuthenticatedRoute: AuthenticatedRouteWithChildren,
  BoardRoute: BoardRouteWithChildren,
  SignInRoute: SignInRoute,
  SignUpRoute: SignUpRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_authenticated",
        "/_board",
        "/sign-in",
        "/sign-up"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_authenticated": {
      "filePath": "_authenticated.tsx",
      "children": [
        "/_authenticated/_global",
        "/_authenticated/_projects"
      ]
    },
    "/_board": {
      "filePath": "_board.tsx",
      "children": [
        "/_board/board/$boardId",
        "/_board/board/"
      ]
    },
    "/sign-in": {
      "filePath": "sign-in.tsx"
    },
    "/sign-up": {
      "filePath": "sign-up.tsx"
    },
    "/_authenticated/_global": {
      "filePath": "_authenticated._global.tsx",
      "parent": "/_authenticated",
      "children": [
        "/_authenticated/_global/billing",
        "/_authenticated/_global/user-settings",
        "/_authenticated/_global/pricing/canceled",
        "/_authenticated/_global/pricing/success",
        "/_authenticated/_global/pricing/",
        "/_authenticated/_global/projects/"
      ]
    },
    "/_authenticated/_projects": {
      "filePath": "_authenticated._projects.tsx",
      "parent": "/_authenticated",
      "children": [
        "/_authenticated/_projects/projects/$projectId/requests/",
        "/_authenticated/_projects/projects/$projectId/settings/",
        "/_authenticated/_projects/projects/$projectId/stats/",
        "/_authenticated/_projects/projects/$projectId/users/",
        "/_authenticated/_projects/projects/$projectId/users/$userId/"
      ]
    },
    "/_authenticated/_global/billing": {
      "filePath": "_authenticated/_global/billing.tsx",
      "parent": "/_authenticated/_global"
    },
    "/_authenticated/_global/user-settings": {
      "filePath": "_authenticated/_global/user-settings.tsx",
      "parent": "/_authenticated/_global"
    },
    "/_board/board/$boardId": {
      "filePath": "_board/board/$boardId.tsx",
      "parent": "/_board"
    },
    "/_board/board/": {
      "filePath": "_board/board/index.lazy.tsx",
      "parent": "/_board"
    },
    "/_authenticated/_global/pricing/canceled": {
      "filePath": "_authenticated/_global/pricing/canceled.tsx",
      "parent": "/_authenticated/_global"
    },
    "/_authenticated/_global/pricing/success": {
      "filePath": "_authenticated/_global/pricing/success.tsx",
      "parent": "/_authenticated/_global"
    },
    "/_authenticated/_global/pricing/": {
      "filePath": "_authenticated/_global/pricing/index.tsx",
      "parent": "/_authenticated/_global"
    },
    "/_authenticated/_global/projects/": {
      "filePath": "_authenticated/_global/projects/index.tsx",
      "parent": "/_authenticated/_global"
    },
    "/_authenticated/_projects/projects/$projectId/requests/": {
      "filePath": "_authenticated/_projects/projects/$projectId/requests/index.tsx",
      "parent": "/_authenticated/_projects"
    },
    "/_authenticated/_projects/projects/$projectId/settings/": {
      "filePath": "_authenticated/_projects/projects/$projectId/settings/index.lazy.tsx",
      "parent": "/_authenticated/_projects"
    },
    "/_authenticated/_projects/projects/$projectId/stats/": {
      "filePath": "_authenticated/_projects/projects/$projectId/stats/index.lazy.tsx",
      "parent": "/_authenticated/_projects"
    },
    "/_authenticated/_projects/projects/$projectId/users/": {
      "filePath": "_authenticated/_projects/projects/$projectId/users/index.lazy.tsx",
      "parent": "/_authenticated/_projects"
    },
    "/_authenticated/_projects/projects/$projectId/users/$userId/": {
      "filePath": "_authenticated/_projects/projects/$projectId/users/$userId/index.lazy.tsx",
      "parent": "/_authenticated/_projects"
    }
  }
}
ROUTE_MANIFEST_END */

import { toast } from '@/hooks/use-toast';
import { valibotResolver } from '@hookform/resolvers/valibot';
import { useParams } from '@tanstack/react-router';
import { PlusCircle } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { InferInput as ValibotInput, maxLength, minLength, object, pipe, string } from 'valibot';

import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';

import { useCreateRequest } from '@/api/requests/queries';

const FormSchema = object({
  name: pipe(
    string(),
    minLength(1, 'Please enter Request title'),
    maxLength(50, 'Title cannot exceed 50 symbols'),
  ),
  description: pipe(
    string(),
    minLength(10, 'Please enter Request description'),
    maxLength(500, 'Title cannot exceed 500 symbols'),
  ),
});

export type FormDataType = ValibotInput<typeof FormSchema>;

export const CreateRequestDialog = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { projectId } = useParams({ strict: false }) as { projectId: string };
  const mutation = useCreateRequest();

  const form = useForm<FormDataType>({
    resolver: valibotResolver(FormSchema),
    defaultValues: {
      name: '',
      description: '',
    },
  });

  useEffect(() => {
    if (!isDialogOpen) form.reset();
  }, [isDialogOpen, form]);

  const onSubmit = (data: FormDataType) => {
    return mutation.mutateAsync(
      { data, projectId },
      {
        onSuccess: () => {
          setIsDialogOpen(false);
          toast({ title: 'Request has been created' });
        },
      },
    );
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <DialogTrigger asChild>
        <Button variant="secondary">
          <PlusCircle className="mr-2 text-muted-foreground" size={20} />
          Create Request
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create Request</DialogTitle>
          <DialogDescription></DialogDescription>
        </DialogHeader>
        <div>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex w-full flex-col space-y-6">
              <div>
                <div className="space-y-4">
                  <FormField
                    disabled={form.formState.isSubmitting}
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Name</FormLabel>
                        <FormControl>
                          <Input placeholder="Request title" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    disabled={form.formState.isSubmitting}
                    control={form.control}
                    name="description"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Description</FormLabel>
                        <FormControl>
                          <Textarea placeholder="Request description" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
              <Button
                className="self-end"
                type="submit"
                disabled={form.formState.isSubmitting || !form.formState.isDirty}
              >
                Submit
              </Button>
            </form>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  );
};
